/* eslint-disable no-shadow */
import {
  Autocomplete,
  Card,
  Checkbox,
  Chip,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useRefreshToken from "hooks/useRefreshToken";
import translate from "i18n/translate";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React examples
import colors from "assets/theme-dark/base/colors";
import { useQuery } from "react-query";
import { enumQueryNames } from "api/reactQueryConstant";
import { whoAmIDetails } from "api/watchmenApi";
import { notificationListAPi } from "api/watchmenApi";
import { getNotificationTypesApi } from "api/watchmenApi";
import NotificationItem from "components/Items/NotificationItem";
import { useMaterialUIController } from "context";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// custom styles for the NotificationItem
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { notificationColors } from "utils/constants";
import { getHallListApi } from "api/watchmenApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useStyle } from "../compareMachine";
import { ThemeProvider } from "@mui/system";
import { calenderDarkTheme } from "../machineShifts/rangepicker";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MDButton from "components/MDButton";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const index = () => {
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(1);
  const [controller] = useMaterialUIController();
  const { language } = controller;
  const navigate = useNavigate();
  const { axiosPrivate, isAuthSet } = useAxiosPrivate();
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const [notifications, setNotifications] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedNotificationType, setSelectedNotificationType] = useState([]);

  const { data: userDetails } = useQuery(
    [enumQueryNames.WHO_AM_I],
    () => whoAmIDetails(axiosPrivate),
    {
      enabled: !!(isAuthSet && auth.Token)
    }
  );

  const { data: notificationTypes = [] } = useQuery(
    [enumQueryNames.GET_NOTIFICATION_TYPE],
    () => getNotificationTypesApi(axiosPrivate),
    {
      enabled: !!(isAuthSet && auth.Token)
    }
  );

  const notificationGroup = tabValue === 0 ? "notifications" : "headlines";
  const { isFetched: notificationFetched, refetch: fetchNotifications } = useQuery(
    [enumQueryNames.GET_NOTIFICATION_ALL],
    () =>
      notificationListAPi(axiosPrivate, {
        language: "en",
        notification_type_ids: selectedNotificationType.length
          ? selectedNotificationType.map((type) => type.id)
          : notificationTypes
              .filter((type) => type.meta_frontend.group === notificationGroup)
              .map((type) => type.id),
        page: page,
        per_page: 20,
        machine_list: selectedMachines.map((machine) => machine.id)
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        setPage((prevPage) => prevPage + 1);
        if (page === 1) {
          setNotifications(data?.notification_list);
        } else {
          setNotifications((notification) => [...notification, ...data?.notification_list]);
        }
      }
    }
  );

  useEffect(() => {
    if (notificationTypes.length) {
      fetchNotifications();
    }
  }, [userDetails, notificationTypes, tabValue, selectedMachines, selectedNotificationType]);

  useEffect(() => {
    refresh();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
      document.documentElement.offsetHeight - 1
    )
      return;
    fetchNotifications();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { data: hallList = [] } = useQuery(
    [enumQueryNames.HALL_LIST],
    () => getHallListApi(axiosPrivate),
    { enabled: !!(isAuthSet && auth.Token) }
  );

  const options = hallList
    ?.map((option) =>
      option?.machine_list?.map((machine) => ({
        firstLetter: option.name,
        hallId: option.id,
        id: machine.id,
        name: machine.name
      }))
    )
    .flat();

  const applyFilterHandler = () => {
    // refetch();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!notificationFetched ? (
        <>
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </>
      ) : (
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" spacing={2} mb={2}>
            <Grid item md={5} sm={12} sx={{ width: "-webkit-fill-available" }}>
              <Autocomplete
                id="machine-list"
                value={selectedMachines}
                multiple
                options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) || []}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect
                limitTags={2}
                renderTags={(value, getTagProps) =>
                  selectedMachines.map((option, index) => (
                    <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label={translate("selectMachine")} />
                )}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <Typography style={{ color: "#FFFFFF" }}>{params.group}</Typography>
                    {params.children}
                  </li>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedMachines.find((selOpt) => selOpt.id === option.id)}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        e.stopPropagation();
                      }}
                    >
                      {option.name}
                    </span>
                  </li>
                )}
                onChange={(event, newValue) => {
                  const multipleItems = newValue.filter(
                    (item) => item.id === newValue[newValue.length - 1].id
                  );
                  if (multipleItems.length > 1) {
                    setSelectedMachines(newValue.filter((item) => item.id !== multipleItems[0].id));
                    setPage(1);
                  } else {
                    setSelectedMachines(newValue);
                    setPage(1);
                  }
                }}
              />
            </Grid>
            <Grid item md={4} sm={12} sx={{ width: "-webkit-fill-available" }}>
              <Autocomplete
                id="notification-list"
                value={selectedNotificationType}
                multiple
                options={
                  notificationTypes.filter(
                    (type) => type.meta_frontend.group === notificationGroup
                  ) || []
                }
                getOptionLabel={(option) => option?.display_name[language?.substring(0, 2)]}
                disableCloseOnSelect
                limitTags={2}
                renderTags={(value, getTagProps) =>
                  selectedNotificationType.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color={notificationColors[option.id]}
                      label={option.display_name[language?.substring(0, 2)]}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label={translate("selectNotificationTypes")} />
                )}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <Typography style={{ color: "#FFFFFF" }}>{params.group}</Typography>
                    {params.children}
                  </li>
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedNotificationType.find((selOpt) => selOpt.id === option.id)}
                    />
                    <Chip
                      onClick={(e) => {
                        e.stopPropagation();
                        e.stopPropagation();
                      }}
                      color={notificationColors[option.id]}
                      sx={{
                        height: "auto",
                        ml: 1
                      }}
                      label={option.display_name[language?.substring(0, 2)]}
                    />
                  </li>
                )}
                onChange={(event, newValue) => {
                  const multipleItems = newValue.filter(
                    (item) => item.id === newValue[newValue.length - 1].id
                  );
                  if (multipleItems.length > 1) {
                    setSelectedNotificationType(
                      newValue.filter((item) => item.id !== multipleItems[0].id)
                    );
                    setPage(1);
                  } else {
                    setSelectedNotificationType(newValue);
                    setPage(1);
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sm={12}
              display="flex"
              justifyContent="right"
              alignItems="center"
            >
              <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={(e, value) => {
                  setTabValue(value);
                  setNotifications([]);
                  setPage(1);
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: colors.info.main,
                    transition: "none"
                  }
                }}
              >
                <Tab
                  variant="gradient"
                  color="info"
                  sx={{
                    padding: "0 14px",
                    height: "36px"
                  }}
                  label="Notification"
                />
                <Tab
                  variant="gradient"
                  color="info"
                  sx={{
                    padding: "0 14px",
                    height: "36px"
                  }}
                  label="Headline"
                />
              </Tabs>
            </Grid>
          </Grid>

          {notifications.length > 0 ? (
            notifications.reduce((acc, data, index, arr) => {
              const currentDate = moment(data.created_at).format("YYYY-MM-DD");
              const previousDate =
                index > 0 ? moment(arr[index - 1].created_at).format("YYYY-MM-DD") : null;

              if (currentDate !== previousDate) {
                acc.push(
                  <Grid
                    key={`divider-${currentDate}`}
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "16px 0"
                    }}
                  >
                    <div style={{ flex: 1, height: "1px", backgroundColor: "#CCCCCC" }}></div>
                    <MDTypography variant="h6" fontWeight="medium" style={{ margin: "0 16px" }}>
                      {moment(data.created_at).format("D MMM YYYY")}
                    </MDTypography>
                    <div style={{ flex: 1, height: "1px", backgroundColor: "#CCCCCC" }}></div>
                  </Grid>
                );
              }

              acc.push(
                <Card key={data.id} sx={{ marginBottom: "6px", borderRadius: "6px" }}>
                  <MenuItem sx={{ width: "100%" }}>
                    <MDBox py={0.5} display="flex" alignItems="center" lineHeight={1} width="100%">
                      <Grid display="flex" justifyContent="space-between" width="100%">
                        <Grid item xs={10}>
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            sx={{ lineHeight: 2, whiteSpace: "wrap" }}
                            onClick={() => {
                              navigate(
                                `/machines/${data.machine_id}?date=${moment(
                                  data.created_at
                                )?.format("YYYY-MM-DD")}`
                              );
                            }}
                          >
                            {data?.payload[language?.substring(0, 2)] || ""}
                            <Chip
                              color={notificationColors[data.notification_type_id]}
                              sx={{
                                height: "auto",
                                ml: 1
                              }}
                              label={
                                notificationTypes.find(
                                  (not) => not.id === data.notification_type_id
                                )?.display_name[language?.substring(0, 2)]
                              }
                            />
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          flex={0.1}
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            textWrap: "nowrap"
                          }}
                        >
                          <MDTypography variant="button" sx={{ ml: 1, fontSize: "smaller" }}>
                            {moment(data.created_at).format("D MMM HH:mm")}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MenuItem>
                </Card>
              );

              return acc;
            }, [])
          ) : (
            <NotificationItem title={translate("No New Notifications")} />
          )}
        </Grid>
      )}
    </DashboardLayout>
  );
};

export default index;
